<template>
  <div
    class="c--sim_item"
    :class="[`sim-item theme-${simTheme}`, { 'my-esim': type === 'my-esims' }]"
    :style="simGradient"
  >
    <template v-if="item.freemium">
      <div
        @click.stop.prevent="freemiumModalOpen = true"
        class="tw-flex tw-bg-white tw-py-8 tw-px-4 tw-rounded-t tw-justify-between tw-items-center"
        data-testid="freemium-header"
      >
        <div class="tw-flex">
          <i
            class="airalo-icon-star tw-text-primary tw-text-4 tw-mr-2"
            :style="{ color: item.operator.gradient_start }"
          />
          <div class="tw-text-4 tw-text-primary tw-font-semibold">
            {{ $t('components.freemium.free-welcome-esim') }}
          </div>
        </div>
        <i class="airalo-icon-arrow-right rtl:tw-rotate-180 tw-text-primary tw-text-3" />
      </div>
    </template>
    <div class="sim-item-header tw-relative">
      <template v-if="packageHasPromotionAndStock">
        <div class="tw-absolute -tw-top-2.5 ltr:tw-left-5 rtl:tw-right-5">
          <PromotionBadge :percentage="pkgMxn_packagePromotionDiscount" />
        </div>
      </template>
      <div class="sim-item-header-info">
        <p class="operator-title" data-testid="operator-title">
          {{ item.label || item.operator.title }}
        </p>
      </div>
      <div class="sim-item-image" :class="{ outOfStockGrayScale: isOutOfStock }">
        <template v-if="item.operator.image && item.operator.image.url">
          <img
            :src="`/assets/images/placeholders/sim-operator-small-${simTheme === 'dark' ? 'dark' : 'light'}.svg`"
            :data-src="item.operator.image.url"
            width="140"
            :alt="item.operator.title || ''"
            v-lazy-load
          />
        </template>
        <template v-else>
          <img
            :src="`/assets/images/placeholders/sim-operator-small-${simTheme === 'dark' ? 'dark' : 'light'}.svg`"
            width="140"
            alt="airalo"
            data-not-lazy
          />
        </template>
      </div>
    </div>

    <div class="sim-item-info" v-if="type === 'store'">
      <ul class="sim-item-list">
        <!-- Coverage -->
        <li v-if="listType === 'local-packages'">
          <sim-item-row
            :theme="simTheme"
            icon="airalo-icon-globe-2"
            :key-text="$t('global.coverage')"
            :value="`${country ? country.title : ''}`"
            class="coverage"
          ></sim-item-row>
        </li>
        <li v-else class="pointered" @click.prevent="clickCallToAction('open-supported-countries')">
          <sim-item-row
            :theme="simTheme"
            icon="airalo-icon-globe-2"
            :key-text="$t('global.coverage')"
            :value="`${item.operator.countries.length} ${$t('global.countries')}`"
            class="coverage button tw-uppercase"
          ></sim-item-row>
        </li>
        <!-- Data -->
        <li>
          <sim-item-row
            :theme="simTheme"
            icon="airalo-icon-arrows-up-and-down"
            :key-text="$t('global.data')"
            :value="pkgMxn_packageHasUnlimitedData ? $t('global.data-unlimited') : item.data"
          ></sim-item-row>
        </li>
        <!-- Voice -->
        <li v-if="pkgMxn_packageHasDVTVoice">
          <sim-item-row
            :theme="simTheme"
            :icon-is-svg="true"
            icon="ic_calls"
            :key-text="$t('global.calls')"
            :value="
              pkgMxn_packageHasUnlimitedVoice
                ? $t('global.calls-unlimited')
                : $t('global.minutes', {
                    amount: item.voice,
                  })
            "
          ></sim-item-row>
        </li>
        <!-- Text -->
        <li v-if="pkgMxn_packageHasDVTText">
          <sim-item-row
            :theme="simTheme"
            :icon-is-svg="true"
            icon="ic_sms"
            :key-text="$t('global.texts')"
            :value="
              pkgMxn_packageHasUnlimitedText
                ? $t('global.texts-unlimited')
                : $t('global.sms', {
                    amount: item.text,
                  })
            "
          ></sim-item-row>
        </li>
        <!-- Validity -->
        <li>
          <sim-item-row
            :theme="simTheme"
            icon="airalo-icon-calendar"
            :key-text="$t('global.validity')"
            :value="`${item.day} ${dayText(item.day)}`"
          ></sim-item-row>
        </li>
        <!-- Price -->
        <li v-if="item.is_stock">
          <sim-item-row
            :theme="simTheme"
            :icon-is-svg="true"
            icon="ic_price_v2"
            :key-text="$t('global.price')"
            :value="pkgMxn_packageNonPromotedPrice.formatted"
            :is-last="true"
            :is-price="true"
            :price-has-promotion="packageHasPromotionAndStock"
            :is-promoted-price="false"
          ></sim-item-row>
        </li>
        <li v-if="packageHasPromotionAndStock">
          <sim-item-row
            :theme="simTheme"
            :value="pkgMxn_packagePromotedPrice.formatted"
            :is-last="true"
            :is-price="true"
            :is-promoted-price="true"
            :discount-percentage="pkgMxn_packagePromotionDiscount"
            :sale-price-end-date-iso="pkgMxn_packagePromotionEndDateIso"
            :tooltip-target-id="tooltipTargetId"
          ></sim-item-row>
        </li>
      </ul>
    </div>

    <div class="sim-item-info" v-else-if="type === 'my-esims'">
      <ul class="sim-item-list">
        <li v-if="item.freemium">
          <sim-item-row
            :theme="simTheme"
            icon="airalo-icon-star"
            :key-text="$t('components.freemium.welcome-esim')"
            :value="$t('components.freemium.free')"
          />
        </li>
        <li>
          <sim-item-row
            :theme="simTheme"
            icon="airalo-icon-myesim"
            key-text="ICCID"
            :value="`${item.iccid}`"
            class="iccid"
          ></sim-item-row>
        </li>
        <li v-if="item.operator.country">
          <sim-item-row
            :theme="simTheme"
            icon="airalo-icon-globe-2"
            :key-text="$t('global.coverage')"
            :value="`${item.operator.country.title}`"
            class="coverage"
          ></sim-item-row>
        </li>
        <li
          v-else-if="!item.operator.country && item.operator.countries && item.operator.countries.length > 0"
          class="pointered"
          @click.prevent="clickCallToAction('open-supported-countries')"
        >
          <sim-item-row
            :theme="simTheme"
            icon="airalo-icon-globe-2"
            :key-text="$t('global.coverage')"
            :value="`${item.operator.countries.length} ${$t('global.countries')}`"
            class="coverage button tw-uppercase"
          ></sim-item-row>
        </li>
        <li class="sim-item-usage-data">
          <sim-item-row
            :theme="simTheme"
            icon="airalo-icon-arrows-up-and-down"
            :key-text="dataUsageLabel"
            :value="usageData && usageData.data ? usageData.data : '...'"
            :loader="(!usageData || !usageData.data) && isLoading"
            :is-last="!showRenewals"
            :is-remaining-usage="true"
            remaining-usage-type="data"
            class="sim-item-row-key-text"
          ></sim-item-row>
        </li>
        <li v-if="checkRemainingVoiceText" class="sim-item-usage-voice">
          <sim-item-row
            :theme="simTheme"
            :icon-is-svg="true"
            icon="ic_calls"
            :key-text="voiceUsageLabel"
            :value="usageData && usageData.data ? usageData.data : '...'"
            :loader="(!usageData || !usageData.data) && isLoading"
            :is-last="false"
            :is-remaining-usage="true"
            remaining-usage-type="voice"
            class="sim-item-row-key-text"
          ></sim-item-row>
        </li>
        <li v-if="checkRemainingVoiceText" class="sim-item-usage-text">
          <sim-item-row
            :theme="simTheme"
            :icon-is-svg="true"
            icon="ic_sms"
            :key-text="textUsageLabel"
            :value="usageData && usageData.data ? usageData.data : '...'"
            :loader="(!usageData || !usageData.data) && isLoading"
            :is-last="false"
            :is-remaining-usage="true"
            remaining-usage-type="text"
            class="sim-item-row-key-text"
          ></sim-item-row>
        </li>
        <li v-if="showRenewals && item.renewal && item.renewal.status === 'enabled'" class="sim-item-usage-renewals">
          <sim-item-row
            :theme="simTheme"
            :icon-is-svg="true"
            icon="ic_subscription"
            :key-text="$t('global.renewal').toUpperCase()"
            :value="item.renewal.data"
            :is-last="true"
            :has-colors-inverted="true"
            :inverted-text-color="item.operator.gradient_end"
            class="sim-item-row-key-text"
          ></sim-item-row>
        </li>
      </ul>
    </div>

    <div
      class="sim-item-bottom"
      :class="{ 'sim-item-has-promotion tw-promo-prices-bg': packageHasPromotionAndStock }"
      v-if="type === 'store'"
    >
      <div class="sim-item-bottom-button d-flex" data-testid="esim-button">
        <b-button block variant="sim-item-btn">{{ bottomButtonText }}</b-button>
      </div>
    </div>

    <div class="sim-item-bottom" v-else-if="type === 'my-esims'">
      <div class="sim-item-bottom-button d-grid d-grid-fit gap-20">
        <template v-if="showRenewals && item.renewal && item.renewal.status === 'enabled'">
          <nuxt-link
            :to="
              localePath({
                name: 'my-esims-detail-id',
                params: {
                  id: item.id,
                },
              })
            "
            class="button"
            :class="{
              'button-secondary-outline': simTheme === 'dark',
              'button-primary-outline': simTheme !== 'dark',
            }"
            data-testid="details-button"
          >
            {{ $t('global.package-details').toUpperCase() }}
          </nuxt-link>
        </template>

        <template v-else-if="eKycOptions.required && eKycOptions.isPending">
          <div
            @click="openKycDetailsModal"
            class="button tw-cursor-pointer"
            :class="[simTheme === 'dark' ? 'button-secondary btn-primary-hv' : 'button-primary']"
          >
            {{ $t('component.esim.item.button.verify-pending') }}
          </div>
        </template>

        <template v-else-if="eKycOptions.required && eKycOptions.isRejectedOrExpired">
          <div
            @click="eKycSubmit"
            class="button tw-cursor-pointer"
            :class="[simTheme === 'dark' ? 'button-secondary btn-primary-hv' : 'button-primary']"
          >
            {{ $t('global.ekyc-procced-resubmit') }}
          </div>
        </template>

        <template v-else>
          <nuxt-link
            :to="
              localePath({
                name: 'my-esims-detail-id',
                params: {
                  id: item.id,
                },
                hash: '#topup',
              })
            "
            class="button"
            :class="{
              'button-secondary-outline': simTheme === 'dark',
              'button-primary-outline': simTheme !== 'dark',
            }"
            data-testid="top-up-button"
            v-if="hasTopups"
            >{{ $t('global.top-up') }}</nuxt-link
          >
          <nuxt-link
            :to="
              localePath({
                name: 'my-esims-detail-id',
                params: {
                  id: item.id,
                },
              })
            "
            class="button"
            :class="{
              'button-secondary btn-primary-hv': simTheme === 'dark',
              'button-primary': simTheme !== 'dark',
            }"
            data-testid="details-button"
          >
            {{ $t('global.details') }}
          </nuxt-link>
        </template>
      </div>
    </div>

    <div class="sim-item-bottom tw-bg-white tw-rounded-b-[7px]" v-if="showCoBrandedLoyaltyRewards">
      <sim-promotion-loyalty-reward :item="item" />
    </div>

    <HowFreemiumWorks :view-modal="freemiumModalOpen" @onClose="freemiumModalOpen = false" />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import SimItemRow from '~/components/sim/item-row.vue'
  import PromotionBadge from '~/components/sim/promotion-badge.vue'
  import SimPromotionLoyaltyReward from '~/components/sim/promotion-loyalty-reward.vue'
  import pkgMxn from '~/mixins/package.js'
  import HowFreemiumWorks from '@/components/freemium/how-freemium-works.vue'

  export default {
    components: {
      HowFreemiumWorks,
      SimItemRow,
      SimPromotionLoyaltyReward,
      PromotionBadge,
    },

    mixins: [pkgMxn(true, 'item')],

    props: {
      type: {
        type: String,
        required: false,
        default: 'store',
      },
      item: {
        type: Object,
        required: true,
      },
      listType: {
        type: String,
        required: true,
      },
      from: {
        type: String,
        required: false,
        default: 'homepage',
      },
      country: {
        type: Object,
        required: false,
        default: null,
      },
      usageData: {
        required: false,
        default: null,
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false,
      },
      showRenewals: {
        type: Boolean,
        required: false,
        default: false,
      },
    },

    data() {
      return {
        styleOutOfStock: {
          stockStyle: 'light',
          backgroundGradient: { start: '#0C0C11', end: '#646469' },
        },
        freemiumModalOpen: false,
      }
    },

    computed: {
      ...mapGetters({
        userIdentities: 'eKyc/get_user_identities',
        userDefaultIdentities: 'eKyc/get_user_default_identities',
        isCoBranded: 'coBranding/getIsCoBranded',
        branding: 'coBranding/getBranding',
        currentCurrencyCode: 'multiCurrency/get_current_currency_code',
      }),
      showCoBrandedLoyaltyRewards() {
        return (
          this.isCoBranded &&
          this.branding?.loyalty_program_usd_conversion &&
          this.branding?.loyalty_program_unit &&
          this.type === 'store' &&
          !this.pkgMxn_packageHasPromotion &&
          !this.isOutOfStock &&
          !this.item?.freemium &&
          this.currentCurrencyCode === 'USD'
        )
      },
      loyaltyPointsReward() {
        return (this.branding?.loyalty_program_usd_conversion * (this.item?.price?.minor_amount / 100))?.toFixed(0) || 0
      },
      simStyle() {
        const style = this.getStyleWithOutOfStock(this.item)
        return style
      },
      simGradient() {
        if (this.simStyle?.background) {
          return this.simStyle.background
        }

        return `background: linear-gradient(${this.$dir() === 'rtl' ? 270 : 90}deg, ${
          this.item.operator.gradient_start
        } 0, ${this.item.operator.gradient_end});`
      },
      simTheme() {
        if (this.simStyle?.style) {
          return this.simStyle.style
        }

        return this.item.operator.style
      },
      isOutOfStock() {
        return this.type !== 'my-esims' ? !!Object.keys(this.styleOutOfStock).length && !this.item.is_stock : false
      },
      checkRemainingVoiceText() {
        return this.usageData?.planType === 'data-voice-text'
      },
      dataUsageLabel() {
        return this.usageData?.isApi ? this.$t('global.remaining') : this.$t('global.data')
      },
      voiceUsageLabel() {
        return this.usageData?.isApi ? this.$t('global.remaining-calls') : this.$t('global.calls')
      },
      textUsageLabel() {
        return this.usageData?.isApi ? this.$t('global.remaining-texts') : this.$t('global.texts')
      },
      tooltipTargetId() {
        return `sim-item-sale-price-${this.item.id}`
      },
      packageHasPromotionAndStock() {
        return this.pkgMxn_packageHasPromotion && !this.isOutOfStock
      },
      hasRenewals() {
        return this.showRenewals && this.item?.renewal && this.item.renewal?.status === 'enabled'
      },
      hasTopups() {
        if (this.hasRenewals) {
          return false
        }
        return typeof this.item.operator.rechargeability === 'boolean'
          ? this.item.operator.rechargeability
          : !this.item.operator.is_prepaid
      },

      isCheckoutByBuyerLocationRestricted() {
        return this.$store.getters['behaviors/get_is_checkout_by_buyer_location_restricted']
      },

      bottomButtonText() {
        if (this.isCheckoutByBuyerLocationRestricted) return this.$t('global.details')
        if (this.from === 'homepage' && !this.item.is_stock) return this.$t('global.esim-out-of-stock')
        if (this.item?.freemium) return this.$t('components.freemium.get-free-esim')
        return this.$t('scenes.package.detail.cta-button-buy')
      },

      eKycOptions() {
        const options = {
          required: false,
          isPending: false,
          isRejectedOrExpired: false,
        }

        if (!this.$featureFlag.isActive('enable_corporate_mode')) {
          return options
        }

        const {
          post_kyc_package_id,
          post_kyc_status,
          operator: { is_kyc_verify },
        } = this.item

        if (is_kyc_verify === 1 && post_kyc_package_id) {
          const kycStatus = post_kyc_status || 'not-verified'

          options.required = !!post_kyc_package_id
          options.isPending = post_kyc_status === 'pending'
          options.isRejectedOrExpired = ['not-verified', 'rejected', 'expired'].includes(kycStatus)
        }

        return options
      },
    },

    methods: {
      getStyleWithOutOfStock(sim) {
        const operator = sim.operator
        let backgroundGradient = null
        let stockStyle = ''

        if (this.type !== 'my-esims') {
          stockStyle = sim.is_stock ? operator.style : this.styleOutOfStock.stockStyle
          backgroundGradient = sim.is_stock
            ? { start: operator.gradient_start, end: operator.gradient_end }
            : { ...this.styleOutOfStock.backgroundGradient }

          if (!sim.is_stock) {
            const outOfStockStyleFromFirebase = this.getOutOfStockStyleFromFirebase()

            if (outOfStockStyleFromFirebase) {
              const { style, gradientStart, gradientEnd } = outOfStockStyleFromFirebase

              stockStyle = style || stockStyle
              backgroundGradient = {
                start: gradientStart || backgroundGradient.start,
                end: gradientEnd || backgroundGradient.end,
              }
            }
          }
        } else {
          stockStyle = operator.style
          backgroundGradient = {
            start: operator.gradient_start,
            end: operator.gradient_end,
          }
        }

        this.styleOutOfStock = {
          ...this.styleOutOfStock,
          stockStyle,
          backgroundGradient,
        }

        const background = `background: linear-gradient(${this.$dir() === 'rtl' ? 270 : 90}deg,${
          backgroundGradient.start
        } 0,${backgroundGradient.end})`

        return { style: stockStyle, background }
      },

      getOutOfStockStyleFromFirebase() {
        const style = this.$featureFlag.isActive('out_of_stock_style')
        const gradientStart = this.$featureFlag.isActive('out_of_stock_gradient_start')
        const gradientEnd = this.$featureFlag.isActive('out_of_stock_gradient_end')

        if (style && gradientStart && gradientEnd) {
          return { style, gradientStart, gradientEnd }
        }

        return null
      },

      dayText(day) {
        return parseInt(day) < 2 ? this.$t('global.day') : this.$t('global.days')
      },

      totalAmount(amount) {
        return amount < 1024
          ? amount + ' MB'
          : parseFloat(amount / 1024)
              .toFixed(2)
              .replace('.00', '') + ' GB'
      },

      getItemRowValue(payload, type) {},

      clickCallToAction(type, _payload) {
        switch (type) {
          case 'buy-now':
            this.$store.commit('set_selected_product_type', 'sim')
            this.$emit('onBuyNow', this.item)
            break
          case 'open-supported-countries':
            if (this.type !== 'my-esims') {
              this.$store.commit('set_selected_product_type', 'sim')
            }

            this.$emit('onOpenSupportedCountries', this.item.operator.countries)
            break
          default:
            break
        }
      },

      eKycSubmit() {
        this.$utils.sendEvent('eKycEvent', { interaction: 'my esims', esim: this.item })

        this.$store.commit('eKyc/SET_VERIFY_MODAL_DATA', {
          type: 'my-esims',
          isShow: true,
          privacyInfo: {
            kyc_type: this.item?.operator?.kyc_type || 'no_agreement',
            privacy_policy_url: this.item?.operator?.privacy_policy_url || '',
            operatorName: this.item?.operator?.title || '',
          },
          package_id: this.item?.post_kyc_package_id,
          isOneTime: this.item.operator.is_kyc_one_time,
          order_id: this.item?.order_id || null,
        })
      },

      openKycDetailsModal() {
        const {
          post_kyc_package_id,
          operator: { is_kyc_one_time },
        } = this.item

        let identity

        if (is_kyc_one_time) {
          identity = (this.userIdentities || []).find((v) => v.packages.id === post_kyc_package_id)
        } else {
          identity = this.userDefaultIdentities
        }

        this.$root.$emit('ekyc-detail-modal', identity)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .sim-item {
    border-radius: 7px;
    background: linear-gradient(90deg, #f4f4f4 0%, #f2f2f2 100%);
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    min-height: 286px;

    [dir='rtl'] & {
      background: linear-gradient(270deg, #f4f4f4 0%, #f2f2f2 100%);
    }

    &-image {
      margin-top: -23px;
      border-radius: 12px;
      box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.3);
      overflow: hidden;
      max-height: 88px;
      min-height: 88px;

      img {
        width: auto;
        height: 88px;
      }
    }

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 13px 20px 20px;
      border-bottom: 1px solid transparent;

      &-info {
        width: 100%;
        max-width: calc(100% - 140px);
        min-height: 63px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        p {
          margin: 0;

          &.operator-title,
          &.country-title {
            max-width: calc(100% - 10px);
          }

          &.country-title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 0.8125rem;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 1.25;

            &.iccid {
              @media (max-width: 575px) {
                font-size: 0.75rem;
              }

              @media (max-width: 374px) {
                font-size: 0.625rem;
              }
            }
          }

          &.operator-title {
            font-size: 1.1875rem;
            font-weight: 600;
            letter-spacing: -0.2px;
            line-height: 1.1578947368;
            word-break: break-word;

            & + .country-title {
              margin-top: 5px;
            }

            & + .btn-sim-item-countries-btn {
              margin-top: 5px;
            }
          }
        }
      }

      .btn.btn-sim-item-countries-btn {
        padding: 0;
        border: none;
        border-radius: 0;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        color: transparent;
        font-size: 0.8125rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.309375;

        span {
          &.header-icon {
            display: inline-flex;
            margin-left: 5px;

            [dir='rtl'] & {
              margin-left: unset;
              margin-right: 5px;
            }
          }
        }
      }
    }

    &-list {
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    &-bottom {
      padding: 20px;
      border-top: 1px solid transparent;

      &-button {
        .btn.btn-sim-item-btn {
          padding: 15px 20px;
          border: 1px solid transparent;
          border-radius: 4px;
          background-color: transparent;

          font-size: 0.75rem;
          font-weight: 700;
          letter-spacing: 1.5px;
          line-height: 1;

          transition: all 0.25s;

          &:focus {
            box-shadow: none;
          }
        }
      }
    }

    &.theme-light {
      .sim-item-header {
        border-bottom-color: rgba(#eeeeee, 0.1);

        p {
          &.operator-title,
          &.country-title {
            color: #ffffff;
          }
        }

        .btn.btn-sim-item-countries-btn {
          color: #ffffff;
        }
      }

      .sim-item-list {
        color: #ffffff;
      }

      .sim-item-bottom {
        border-top-color: rgba(#eeeeee, 0.1);
      }

      .sim-item-bottom-button {
        .btn.btn-sim-item-btn {
          border-color: #ffffff;
          color: #ffffff;

          &:hover,
          &:focus {
            background-color: #ffffff;
            color: #4a4a4a;
          }
        }
      }
    }

    &.theme-dark {
      .sim-item-header {
        border-bottom-color: rgba(#343434, 0.1);

        p {
          &.operator-title,
          &.country-title {
            color: #4a4a4a;
          }
        }

        .btn.btn-sim-item-countries-btn {
          color: #4a4a4a;
        }
      }

      .sim-item-list {
        color: #4a4a4a;
      }

      .sim-item-bottom {
        border-top-color: rgba(#343434, 0.1);
      }

      .sim-item-bottom-button {
        .btn.btn-sim-item-btn {
          border-color: #4a4a4a;
          color: #4a4a4a;

          &:hover,
          &:focus {
            background-color: #4a4a4a;
            color: #ffffff;
          }
        }
      }
    }

    &.theme-light,
    &.theme-dark {
      .sim-item-has-promotion {
        &.sim-item-bottom {
          border-top-color: rgba(255, 255, 255, 0.2);
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px;
        }
        .sim-item-bottom-button {
          .btn.btn-sim-item-btn {
            border-color: #ffffff;
            color: #ffffff;

            &:hover,
            &:focus {
              background-color: #ffffff;
              color: #4a4a4a;
            }
          }
        }
      }
    }
  }
</style>
